@import "../../styles/style-guide.scss";

.question-pool-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0 1rem;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 13rem);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.question-pool-table-container {
  & .MuiTableCell-root {
    padding: 0.5rem 1rem !important;
    white-space: nowrap;
  }
}
