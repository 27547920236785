@import "../../styles/style-guide.scss";

.drawer-items-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;

  &.MuiGrid-root {
    gap: 0;
    justify-content: flex-start;
  }

  .drawer-items {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    width: 2.344rem;
    margin: 0.625rem 0;

    a:first-child {
      border-radius: 50%;
      background-color: $lightgrey;

      &:hover {
        background-color: $pink;
      }
    }
  }

  .drawer-icon-label {
    text-align: center;
  }
}
