@import "../../styles/style-guide.scss";

.module-no-data-container {
  border: 0.2rem solid $lightgrey;
  height: calc(100vh - 13rem);
}

.bmv-header {
  display: flex;
  align-items: center;
}

.bmv-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 13rem);
    overflow: scroll;
  }
  .margin-bottom-5 {
    margin-bottom: 1.375rem;
  }
}
.bmv-header {
  height: 2rem;
}

.bmv-container {
  border: 0.1rem solid $grey-border;
}

.bmv-right-info {
  border-style: solid;
  border-left-width: 0.1rem;
  border-color: $grey-border;

  .bmv-info-box1,
  .bmv-info-box2,
  .bmv-info-box3,
  .bmv-info-box4,
  .bmv-info-box5,
  .bmv-info-box6 {
    border-style: solid;
    border-color: $grey-border;
  }

  .bmv-info-box1 {
    border-right-width: 0.1rem;
    border-bottom-width: 0.1rem;
  }
  .bmv-info-box2 {
    border-bottom-width: 0.1rem;
  }
  .bmv-info-box3 {
    border-right-width: 0.1rem;
    border-bottom-width: 0.1rem;
  }
  .bmv-info-box4 {
    border-bottom-width: 0.1rem;
  }
  .bmv-info-box5 {
    border-right-width: 0.1rem;
  }
}
.bmv-info-box {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

////////////////////////////////////////////////////////////////////
/// batch modal//////////////////////////////////////////////////
.batch-modal-name {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.create-batch-modal-table-container {
  &.MuiTableContainer-root {
    max-height: calc(100vh - 28rem);
  }

  .no-data-container {
    width: 100%;
  }
}

////////////////////////////////////////////////////////////////

.module-chip-status {
  & .MuiChip-label {
    font-weight: 500;
    padding: 0 0.313rem;
  }
}
