@import "../../styles/style-guide.scss";

.topic-question-head-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .topic-question-head-left {
    display: flex;
    align-items: center;
  }
  .topic-question-head-right {
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
.question-options {
  padding: 0.438rem 1rem;
  background-color: #fbf8fe;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
}

.interview-fields {
  .question-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .interview-options {
    margin-top: 0.5rem;
  }
  .save-btn {
    margin-top: 0.5rem;
    width: 5.188rem;
  }

  .options {
    background: #fbf8fe;
    border: 0.1rem solid #e6dbf0;
    border-radius: 0.25rem;
    box-shadow: none;
    height: 2rem;
    min-height: 0;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;

    & .MuiFormControlLabel-label {
      font-size: 0.813rem;
    }

    &.single-select-options {
      margin-left: 0px;
      margin-right: 0px;
    }
    &.multi-select-options {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .selected-option {
    background-color: #05b14e;
    color: #fff;
    border: 0.1rem solid #05b14e;
  }
}
