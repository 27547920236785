@import "../../styles/style-guide.scss";

.login-page-container {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-card {
    width: 30rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 0;
    border-radius: 1rem;
    background: hsla(0, 0%, 100%, 0.2);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    backdrop-filter: blur(1rem);
  }

  .ttn-image {
    width: 10rem;
    height: 10rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .login-card-text {
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $grey900;
    letter-spacing: 0.1rem;
    font-weight: 700;
  }

  .login-card-button {
    background-color: $pink;
    color: $white;
    border-radius: 0.5rem;
    height: 2.6rem;
    padding: 1rem 6rem;
    font-size: 1rem;
    font-weight: 700;
    text-transform: none;

    & .MuiButton-startIcon {
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    &:hover {
      background-color: $pink;
      color: $white;
    }
  }
}
