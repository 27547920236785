@import "../../styles/style-guide.scss";

.comment-dialog-title {
  &.MuiDialogTitle-root {
    padding: 2rem 2rem 1rem;
    font-size: $font_16;
    font-weight: 500;
    border-bottom: 0.063rem solid #9a8f8f70;
  }
}

.comment-dialog-action {
  &.MuiDialogActions-root {
    padding: 0rem 2rem 1rem;
  }
}

.comment-main-container {
  max-height: 38rem;
  overflow-y: scroll;
  & .disabledBox {
    opacity: 0.5;
    pointer-events: none;
  }

  .comment-show-container {
    padding: 0.875rem;
    display: flex;
    flex-direction: column;
    background-color: $light-white;
    border: 0.1rem solid $light-white-border;
    border-radius: 0.438rem;
    margin-bottom: 0.625rem;
    min-height: 2rem;

    .comment-show-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .comment-show-body {
      display: flex;
      align-items: center;
      margin-top: 0.563rem;
      justify-content: space-between;
    }

    .link-btn {
      margin-top: 1rem;
      width: 7rem;
      padding: 0 1rem;
    }
  }
}
.show-delete {
  padding: 1rem;
  max-height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(251, 87, 88, 0.1);
  border: 0.1rem solid rgb(251, 87, 88);
  border-radius: 0.438rem;
  margin-bottom: 1rem;
}

.delete-dialog-text {
  margin-bottom: 1rem !important;
}

.delTagButtonStyle {
  height: 1.563rem !important;
  width: 4.603rem;
  border-radius: 0.4rem !important;
  &:first-child {
    margin-right: 1rem;
  }
}

.comment-action-buttons {
  display: flex;
  align-items: center;
}

.comment-description {
  padding-right: 1rem;
  width: 100%;

  & .MuiInputBase-root {
    padding: 0.25rem 0.5rem;

    textarea {
      font-size: 0.75rem;
    }
  }
}

.comment-area {
  margin-top: 0 !important;

  .MuiInputBase-multiline {
    border-radius: 0.438rem;
  }
}
