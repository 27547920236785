@mixin font($font-family, $font-file, $font-weight) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    src: url($font-file + ".ttf") format("truetype");
  }
}

@include font("Poppins-Regular", "../assets/fonts/Poppins-Regular", 400);
@include font("Poppins-Medium", "../assets/fonts/Poppins-Medium", 500);
