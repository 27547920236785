@import "../../styles/style-guide.scss";

.addTagButtonStyle {
  display: flex;
  align-items: center;
  height: 2.75rem !important;
  width: 100%;
  border-radius: 0.6rem !important;
}
.addTagButtonStyle:hover {
  background: $pink !important;
  color: #fff;
}
.delete-dialog-title {
  padding: 2rem 2rem 0 !important;
  margin-bottom: 1.5rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  white-space: pre-line;
}
.delete-dialog-action {
  padding: 0 2rem 1rem !important;
}
