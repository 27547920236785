@import "../../styles/style-guide.scss";

.assessment-questions-head-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .assessment-questions-head {
    display: flex;
    align-items: center;
  }

  .assessment-questions-head-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .assessment-questions-dashed-btn {
    height: 2.5rem !important;
  }
}

.assessment-questions-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 13rem);
    overflow: scroll;
  }
}
.margin-bottom-5 {
  margin-bottom: 1.375rem;
}

.assessment-questions-container {
  border-radius: 0.375rem;
  background-color: #f9f9f9;
}

.assessment-accordion-summary {
  &.MuiAccordionSummary-root {
    box-shadow: none;
    border-radius: 0.5rem;
    border: 0.1rem solid $purple-border;
    background: $purple-background;
    min-height: 0;
    height: auto;
    align-items: flex-start;

    &.Mui-expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
      background-color: $white;
    }

    & .MuiAccordionSummary-content {
      flex-direction: column;
    }
    .related-chip {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
    }
    .related-chip-info {
      display: flex;
      align-items: center;

      .level-chip {
        margin-right: 1rem;
      }
      .type-chip {
        margin-right: 1rem;
      }
      .more-info:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.assessment-info-box {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
