@import "../../styles/style-guide.scss";

.submit-dialog-title {
  &.MuiDialogTitle-root {
    padding: 2rem 2rem 1.2rem;
    font-size: $font_16;
    font-weight: 500;
    border-bottom: 0.063rem solid #9a8f8f70;
  }
}

.submit-dialog-action {
  &.MuiDialogActions-root {
    padding: 0rem 2rem 1rem;
    justify-content: start;
  }

  & .submitButtonStyle {
    height: 2.75rem !important;
    width: 96% !important;
    border-radius: 0.6rem !important;
  }
  & .submitButtonStyle:hover {
    background: $pink !important;
    color: #fff;
  }
}
.submit-main-container {
  max-height: 35rem;
  overflow-y: scroll;

  .input-file-width {
    width: 97%;
    padding-right: 0.813rem !important;

    .fileError {
      color: #d32f2f;
      margin-top: 0.313rem;
      margin-bottom: 0.625rem;
      margin-left: 0.875rem;
    }
  }
}
.input-comment-width {
  width: 95%;
  padding-right: 0.813rem !important;
}
.input-files-chip {
  &.MuiChip-root {
    border-radius: 0.4rem;
    margin: 0.1rem !important;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem;
  }

  & .MuiChip-label {
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    font-size: 0.625rem;
    font-weight: 500;
  }
}

.file-upload-area {
  background-color: rgba(248, 248, 248, 1) !important;
  text-transform: none !important;
  border: none !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

  .file-upload-instruction {
    line-height: 1;
  }
}
