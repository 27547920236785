@import "../../styles/style-guide.scss";

.user-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.user-roles-container {
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 0.4rem 0;

  .roles-chip-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0.5rem;

    .user-roles-chip {
      &.MuiChip-root {
        border-radius: 0.4rem;
        margin: 0.2rem 0.2rem 0.2rem 0 !important;
        height: 1.375rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 0.4rem;
      }

      & .MuiChip-label {
        font-size: $font_12;
        font-weight: 500;
      }
    }
  }
}
