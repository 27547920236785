/* Reset CSS */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
tspan {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  font-style: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-touch-callout: none;
}
/* ===== Scrollbar CSS ===== */
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 0.7rem;
}
.MuiAutocomplete-listbox::-webkit-scrollbar {
  scrollbar-width: auto;
}
*::-webkit-scrollbar-track {
  background: #ffffff;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #c5b7d3;
  border-radius: 1rem;
  border: 0.3rem solid #ffffff;
}

*::-moz-scrollbar-button:decrement,
::-moz-scrollbar-button:increment,
::-webkit-scrollbar-button:decrement,
::-webkit-scrollbar-button:increment {
  width: 0px;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #c5b7d3 #ffffff;
  }
}

html {
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  z-index: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2e1c41;
}

input,
select,
textarea {
  font: inherit;
  line-height: inherit;
}

$tablet-screen: 576px;
$thirteen-inch-laptop-screen: 768px;
$laptop-small-screen: 1024px;
$laptop-15inch-screen: 1366px;
$laptop-large-screen: 1920px;
$twok-screen: 2560px;
$fourk-screen: 3840px;
