@import "../../styles/style-guide.scss";

.body-container {
  margin-top: 2rem;

  .performance-parent-container {
    display: flex;
    flex-direction: column;
    &.MuiPaper-root {
      padding: 1rem;
      border: 0.2rem solid $lightgrey;
      height: calc(100vh - 13rem);
      overflow-y: scroll;
    }

    .no-data-container {
      height: calc(100vh - 13rem);
    }
    .no-performance-container {
      height: 11rem;
    }
    .coverHeader {
      height: 100% !important;
    }
    .double-head-table {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 0.1rem solid $purple-border;

      .double-head-table-title {
        padding: 0.5rem;
      }
      .double-head-table-sub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $purple-border;
        padding: 0.1rem 0rem;
      }
    }
    .double-head-table-border-right {
      border-right: 0.1rem solid $purple-border;
    }

    .double-head-table-body {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0rem;
    }
  }
}
