@import "../../styles/style-guide.scss";

.icon-buttons {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  .floating-badge {
    position: absolute;
    top: -1.113rem;
    right: -1rem;
    background-color: $pink;
    color: $white;
    border-radius: 50%;
    padding: 0.125rem 0.313rem;
    font-size: 0.625rem;
    height: 1.25rem;
    width: 1.25rem;
  }
}
