@import "../../styles/style-guide.scss";

.download-file-btn {
  width: 100% !important;
  height: 2.75rem !important;
  border-radius: 0.4rem !important;
}
.download-file-btn:hover {
  background: $pink !important;
  color: #fff;
}
