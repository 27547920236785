@import "../../styles/style-guide.scss";

.module-no-data-container {
  border: 0.2rem solid $lightgrey;
  height: calc(100vh - 13rem);
}

.assessment-panel-header {
  display: flex;
  align-items: center;
}

.assessment-panel-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 13rem);
    overflow: scroll;
  }
  .margin-bottom-5 {
    margin-bottom: 1.375rem;
  }
}
.assessment-panel-header {
  height: 2rem;
}

.assessment-panel-container {
  border: 0.1rem solid $grey-border;
}

.topics-parent-container {
  padding: 1rem;
}

.assessment-panel-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  border-bottom: 0.1rem solid $grey-border;

  &:last-child {
    border-bottom: 0;
  }

  .assessment-left-rows {
    border-right: 0.1rem solid $grey-border;
  }
}

.assessment-panel-info-box {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .assessment-insturctions-details {
    font-size: 0.75rem;
    font-weight: 400;

    & ul,
    ol {
      list-style-position: inside;
    }
    em {
      font-style: italic;
    }
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.17em;
    }
    a {
      color: #0066cc;
    }
  }
}

////////////////////////////////////////////////////////////////////
/// batch modal//////////////////////////////////////////////////
.batch-modal-name {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.create-batch-modal-table-container {
  &.MuiTableContainer-root {
    max-height: calc(100vh - 28rem);
  }

  .no-data-container {
    width: 100%;
  }
}

////////////////////////////////////////////////////////////////

.module-chip-status {
  & .MuiChip-label {
    font-weight: 500;
    padding: 0 0.313rem;
  }
}
