@import "../../styles/style-guide.scss";

.sidebar-container {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 2.875rem);

  .sidebar-link-tag {
    width: 100%;
    padding: 0 1rem;

    .sidebar-list-item-btn {
      &.MuiListItemButton-root {
        padding: 0.44rem 0.6rem;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
      }
    }
    .sidebar-link-icon-container {
      &.MuiListItemIcon-root {
        min-width: 2rem;
      }
      .sidebar-link-icon {
        width: $font_16;
        height: $font_16;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    .sidebar-link-text {
      & .MuiTypography-root {
        font-size: $font_12 !important;
        font-weight: 500;
      }
    }
    &:hover {
      .sidebar-link-text {
        & .MuiTypography-root {
          color: $pink;
          font-size: $font_12;
        }
      }
    }
    .sidebar-list-item-active-btn {
      &.MuiListItemButton-root {
        padding: 0.44rem 0.6rem;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
      }
      &:hover {
        border: none;
      }
      .sidebar-link-text {
        & .MuiTypography-root {
          color: $pink;
        }
      }
    }
  }

  .sidebar-footer {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-footer-logo {
      width: 1rem;
      height: 1rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 0.6rem;
    }
    .sidebar-footer-text {
      font-size: $font_9;
    }
  }
}

.sub-menu-item {
  margin-bottom: 1rem;
  padding-left: 2.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}
.collapsed {
  .sidebar-link-tag {
    .sidebar-list-item-btn {
      &.MuiListItemButton-root {
        flex-direction: column;
      }
    }
    .sidebar-link-icon-container {
      &.MuiListItemIcon-root {
        min-width: 2rem;
        justify-content: center;
      }
      .sidebar-link-icon {
        width: $font_16;
        height: $font_16;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    .sidebar-link-text {
      & .MuiTypography-root {
        font-size: $font_9 !important;
        text-align: center;
      }
    }
  }
  .sidebar-footer {
    flex-direction: column;
    .sidebar-footer-logo {
      margin-bottom: 0.6rem;
      margin-right: 0;
    }
    .sidebar-footer-text {
      text-align: center;
    }
  }
  & .sub-menu-item {
    padding-left: 1rem;
  }
  & .sidebar-link-tag {
    padding: 0;
  }
}
