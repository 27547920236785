@import "../../styles/style-guide.scss";

.navbar-container {
  background-color: $white;
  box-shadow: 0rem 0.6rem 3rem 0rem #0000000d !important;

  .navbar-logo {
    display: flex;
    align-items: center;
    font-size: 4rem;
    min-width: 18rem;

    .nw-full-logo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: inherit;
      height: 1.719rem;
      min-width: 18rem;
    }
    .nw-small-logo {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: auto;
      min-width: 10rem;
    }
  }

  & .MuiToolbar-root {
    padding: 1rem;
    height: 5.125rem !important;
  }

  .icon-button {
    & .Mui-focusVisible {
      & :focus-visible {
        box-shadow: 0;
      }
    }
  }

  .nav-user-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      #user-avatar {
        border: 0.2rem solid $pink;
      }
    }
    #user-avatar {
      width: 2.5rem;
      height: 2.5rem;
    }

    .user-avatar-down-icon {
      width: 0.9rem;
      height: 0.275rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-left: 0.6rem;
    }
  }
}

.mobile-drawer-icon {
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-position: center;
}

.drawer-tooltip {
  font-size: 2rem !important;
  margin-top: 1rem;
}
