@import "../../styles/style-guide.scss";

.modal-drawer-container {
  &.MuiDrawer-root {
    & .MuiBackdrop-root {
      background-color: $backdrop-purple;
    }
  }
  & .MuiDrawer-paper {
    padding-top: 5.125rem !important;
    height: 100%;
    width: 29rem;
    padding: 1rem 0rem;
  }

  .dialog-title {
    padding: 2rem 2rem 1.2rem;
    font-size: $font_16;
    font-weight: 500;
    border-bottom: 0.063rem solid #9a8f8f70;
  }

  .dialog-content {
    padding: 1.313rem 2rem;
    margin-bottom: 0.563rem;
    padding-top: 1rem !important;
  }

  .text-input {
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
  .description-text-input {
    margin-top: 0;
    margin-bottom: 0.625rem;
  }
}
