@import "../../styles/style-guide.scss";

.tabs-container {
  border: 0.1rem solid $purple-border;

  .tab-head-container {
    & .MuiTabs-flexContainer {
      border-bottom: 0.1rem solid $purple-border;
    }
    & .MuiTab-root {
      font-size: $font_14;
      padding: 0 2rem;
      color: $grey-text;
      &.Mui-selected {
        color: $pink;
      }

      &.Mui-disabled {
        color: $grey-text;
        opacity: 0.4;
      }
    }

    .table-head-row {
      background-color: $purple-background;
    }

    .tabs-header-style {
      text-transform: capitalize;
    }
  }

  .grid-flex-boxes {
    border: 0.1rem solid $grey-border;
    padding: $font_14 $font_20;
    border-bottom: none;
    border-right: none;

    display: flex;
    flex-direction: column;
  }

  .custom-bottom-border {
    border-bottom: 0.1rem solid $grey-border;
  }
  .custom-right-border {
    border-right: 0.1rem solid $grey-border;
  }

  .presenters-table-body {
    .table-body-row {
      .MuiAutocomplete-inputRoot,
      .MuiFormLabel-root {
        font-size: 0.75rem;
      }
      .presenters-start-date,
      .presenters-mode,
      .presenters-meet {
        .MuiOutlinedInput-root {
          font-size: 0.75rem;
        }
      }
      .presenters-mode {
        .MuiOutlinedInput-root {
          .MuiSelect-select {
            height: 1.375rem;
            align-items: center;
            display: flex;
          }
        }
      }
      .row-contains-error {
        vertical-align: top;
      }
      .margin-bottom-3 {
        margin-bottom: 0.3rem;
      }
      .margin-bottom-5 {
        margin-bottom: 0.5rem;
      }
      .custom-data-margin {
        margin-left: 0.2rem;
        margin-bottom: 0.1rem;
      }
    }

    .mode-multiselect {
      & .MuiSelect-select {
        &.MuiInputBase-input {
          &.MuiOutlinedInput-input {
            padding: 6px 39px 6px 6px;
          }
        }
      }
    }
  }
}

.details-tab-accordion-summary {
  &.MuiAccordionSummary-root {
    height: 3.938rem !important;
  }
}

.attendance-input {
  & .MuiOutlinedInput-input {
    padding: 0 0.4rem;
    height: 1.875rem !important;
    border: 0.031rem solid #675b76;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.agenda-details {
  padding: 1rem;
  background-color: $purple-background;

  & ul,
  ol {
    list-style-position: inside;
  }
  em {
    font-style: italic;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  a {
    color: #0066cc;
  }
}

.exercise-details {
  padding: 0.938rem;
  background-color: $purple-background;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.625rem;
}

.table-action-flex {
  display: flex;
  align-items: center;
}

.tabs-no-data {
  width: 100%;
  height: 100%;
  display: flex;
}
