@import "../../styles/style-guide.scss";

.question-chip {
  &.MuiChip-root {
    color: $white;
    border-radius: 0.4rem;
    margin-right: 0.4rem;
    height: 2rem;

    & .MuiChip-label {
      font-weight: 500;
      font-size: 1.2rem;
    }
  }
}

.question-paper-container {
  justify-content: space-between;

  &.MuiPaper-root {
    padding: 1rem 1rem 0;
    border: 0.2rem solid $lightgrey;
    height: calc(100vh - 13rem);
  }
}

.question-details-select {
  padding: 0.625rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.625rem;
}

.question-details {
  padding: 0.625rem;
  background-color: $purple-background;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.625rem;
  border-radius: 0.313rem;
}
