@import "../../styles/style-guide.scss";

.table-parent-container {
  .no-data-container {
    height: calc(100vh - 11rem);
  }

  .topic-paper-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.MuiPaper-root {
      padding: 1rem 1rem 0;
      border: 0.2rem solid $lightgrey;
      height: calc(100vh - 11rem);
    }

    .coverHeader {
      height: 100% !important;
    }

    .double-head-table {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-left: 0.1rem solid $purple-border;

      .double-head-table-title {
        padding: 0.5rem;
      }
      .double-head-table-sub {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: $purple-border;
        padding: 0.1rem 0rem;
      }
    }
    .double-head-table-border-right {
      border-right: 0.1rem solid $purple-border;
    }

    .double-head-table-body {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0.5rem 0rem;
    }
  }
  .width-7 {
    width: 7rem;
  }

  .table-input-dialog {
    & .MuiDrawer-paper {
      display: flex;
      flex-direction: column;
      max-width: 46.4rem;
      padding: 10rem 2rem 1rem 2rem;
      justify-content: space-between;
    }
  }
}

.topic-description {
  &:hover {
    cursor: pointer;
  }
}
.MuiGrid-root {
  position: relative;
}

.main-page-container {
  .MuiCircularProgress-root {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.topic-search-input {
  & .MuiInputBase-root {
    &.MuiOutlinedInput-root {
      height: 1.875rem !important;
      background: white;
    }
  }
}
