@import "../../styles/style-guide.scss";

.create-option-modal-title {
  padding: 1.2rem 2rem 0rem !important;
}

.create-option-input {
  .MuiInputBase-input {
    font-size: 0.75rem;
  }
}

.create-option-btn {
  width: 100% !important;
  height: 2.5rem !important;
  border-radius: 0.4rem !important;
}
.create-option-btn:hover {
  background: $pink !important;
  color: #fff;
}
