@import "../../styles/style-guide.scss";

.body-container {
  margin-top: 2rem;

  .assessment-questions-parent-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.MuiPaper-root {
      padding: 1rem;
      border: 0.2rem solid $lightgrey;
      height: calc(100vh - 13rem);
      overflow-y: scroll;
    }

    .no-data-container {
      height: calc(100vh - 11rem);
    }

    .accordian-pagination-container {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
