@import "../../styles/style-guide.scss";

.toastContainer {
  color: #2e1c41 !important;
  background-color: #fef7e8;
  border: 1px solid #f3e0bc;
  border-radius: 0.625rem !important;
  width: 100%;
  margin-top: 0.625rem;
  margin-bottom: 0.5rem;
  padding: 0.438rem 1.25rem !important;
  white-space: pre-line;
}
