@import "../../styles/style-guide.scss";

.user-drawer {
  &.MuiDrawer-root {
    & .MuiBackdrop-root {
      background-color: $backdrop-purple;
    }
  }
  & .MuiDrawer-paper {
    padding-top: 5.3rem !important;
    height: 100%;
    width: 25.438rem;
    padding: 0rem 1rem 1rem 1.5rem;
  }

  .user-drawer-container {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-content: space-between;
    flex-direction: column;
    flex-flow: column;

    &.css-11lq3yg-MuiGrid-root {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .user-drawer-avatar-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $light-white;
      border-radius: 1rem;
      width: 100%;
      height: auto;
      padding: 1rem;
      margin-bottom: 1rem;

      .card-avatar {
        width: 6.75rem;
        height: 6.75rem;
        margin-bottom: 1rem;
        border: 0.4rem solid $white;
        box-shadow: 0px 4px 14px 0px #00000026;
      }

      .close-icon-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 0;
        .close-drawer-icon {
          width: $font_15;
          height: $font_15;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    .user-drawer-table-container {
      .user-drawer-table-cells {
        &.MuiTableCell-root {
          border: 0;
          padding: 0.3125rem 0;
        }
      }
    }

    .drawer-logout-btn {
      width: 100%;
      height: 2.75rem;
      background-color: $pink;
      color: $white;
      border-radius: 0.6rem;
    }
  }
}
