@import "../../styles/style-guide.scss";

.attendees-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 12rem);
    display: flex;
    justify-content: space-between;
  }
}
.attendees-status-table {
  .table-container {
    height: calc(100vh - 17rem);
  }
}

.coverHeader {
  height: 100% !important;
}

.double-head-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 0.1rem solid $purple-border;

  .double-head-table-title {
    padding: 0.5rem;
  }
  .double-head-table-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $purple-border;
    padding: 0.1rem 0rem;
  }
}
.double-head-table-border-right {
  border-right: 0.1rem solid $purple-border;
}

.double-head-table-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
}

.width-10 {
  width: 5rem;
}

.width-15 {
  width: 10rem;
}
