@import "../../styles/style-guide.scss";

.add-room-title {
  padding: 1.3rem 2rem 0.5rem !important;
}

.add-room-content {
  padding: 0 2rem 1rem !important;
}

.add-room-btn {
  width: 100% !important;
  height: 2.5rem !important;
  border-radius: 0.4rem !important;
}
.add-room-btn:hover {
  background: $pink !important;
  color: #fff;
}
