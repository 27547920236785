@import "../../styles/style-guide.scss";

.mcq-paper-container {
  width: 100%;
  height: 100%;

  .mcq-main-container {
    height: calc(100vh - 7.2rem);
    overflow-y: scroll;

    .assessment-submitted {
      font-size: 1.3rem;
      text-align: center;
      color: #de1186;
      border: 0.1rem solid #de1186;
      padding: 1rem;
      border-radius: 0.625rem;
      box-shadow: 2px 2px 7px #de1186;
    }

    .contained-items {
      flex-grow: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .mcq-top-details {
        display: flex;
        justify-content: end;
        align-items: center;
      }
    }

    .start-button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      & .MuiButtonBase-root {
        max-width: 18.75rem;
      }
    }

    .instructions {
      .upper-container {
        border: 0.1rem solid $grey-border;
      }

      .instruction-details {
        border-style: solid;
        border-left-width: 0.1rem;
        border-color: $grey-border;
      }
      .instruction-details-to-start {
        border-style: solid;
        border-top-width: 0.1rem;
        border-color: $grey-border;
      }

      .instruction-info {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & ul,
        ol {
          list-style-position: inside;
        }
        em {
          font-style: italic;
        }
        h1 {
          font-size: 2em;
        }
        h2 {
          font-size: 1.5em;
        }
        h3 {
          font-size: 1.17em;
        }
        a {
          color: #0066cc;
        }
      }

      .duration-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #fff2f9;
        .duration-box {
          display: flex;
          flex-direction: column;
        }
      }

      .countdown-timer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f6ff;

        .time-units {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #3498db;
          padding: 0.525rem;
          border-radius: 0.625rem;
          min-width: 3.5rem;
          color: #fff;
        }

        .seperator {
          padding: 0 10px;
          color: #555;
          margin-bottom: 1rem;
          font-weight: 600;
        }
      }
    }
    .mcq-section {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 23.6rem);
    }

    .mcq-details {
      display: flex;
      height: calc(100vh - 28.3rem);
      border: 0.125rem solid $grey-border;

      .question-section {
        border-left: 0.125rem solid $grey-border;
        overflow-y: scroll;

        .question-title {
          margin-bottom: 0.625rem;
        }

        .question-description {
          margin-bottom: 1.25rem;
        }

        .mcq-options {
          padding-left: 0.688rem;
        }

        .options {
          background: #fbf8fe;
          border: 0.1rem solid #e6dbf0;
          border-radius: 0.25rem;
          box-shadow: none;
          height: 2.5rem;
          min-height: 0;
          padding-left: 1rem;
          margin-bottom: 0.5rem;
          width: 100%;

          & .MuiFormControlLabel-label {
            font-size: 0.813rem;
          }
        }

        .selected-option {
          background-color: #05b14e;
          color: #fff;
          border: 0.1rem solid #05b14e;
        }
      }
    }

    .mcq-action {
      padding: 1.875rem 0;
      display: flex;
      align-items: center;
      flex: 0 0 auto;
    }

    .custom-progressbar {
      width: 100%;
      background: #e8f5e9;
    }

    .progress-percentage {
      position: absolute;
      color: #e8f5e9;
      font-weight: bold;
      transition: left 0.5s linear, transform 0.5s linear;
    }

    .question-tiles-sidebar {
      padding: 0.75rem 1.25rem;
      overflow-y: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .question-tiles {
        width: 2.2rem;
        height: 2.2rem;
        cursor: pointer;
        border-radius: 50%;
        border: 0.125rem solid #f00082;
        margin-bottom: 0.625rem;
        &:last-child {
          margin-bottom: 0;
        }

        .question-numbers {
          display: flex;
          align-items: center;
          justify-content: center;

          & .MuiTypography-root {
            font-size: 0.813rem;
          }
        }
      }

      .attempted-question-tile {
        border: 0.125rem solid #3087e9;
      }

      .selected-question-tile {
        border: 0.125rem solid #f00082;
      }
    }
  }
}
