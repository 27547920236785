@import "../../styles/style-guide.scss";

.add-role-title {
  padding: 2rem 2rem 0 !important;
  font-size: $font_16 !important;
  font-weight: 500 !important;
}

.add-role-dropdown {
  padding: 1.25rem 1.875rem 0 !important;
}

.add-role-btn {
  width: 100% !important;
  height: 2.75rem !important;
  border-radius: 0.4rem !important;
}
.add-role-btn:hover {
  background: $pink !important;
  color: #fff;
}
