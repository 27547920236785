@import "../../styles/style-guide.scss";

.closeButton {
  position: absolute;
  right: -0%;
  top: 0%;
  transform: translate(50%, -50%);
  padding: 10px;
  border-radius: 50%;
  background-color: $white;
}
.toastContainer {
  margin-bottom: 90px;
}
