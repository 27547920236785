@import "../../styles/style-guide.scss";

.agenda-modal {
  & ul,
  ol {
    list-style-position: inside;
  }
  em {
    font-style: italic;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  a {
    color: #0066cc;
  }
}
