@import "../../styles/style-guide.scss";

.session-paper-container {
  justify-content: space-between;

  &.MuiPaper-root {
    padding: 1rem 1rem 0;
    border: 0.2rem solid $lightgrey;
    height: calc(100vh - 13rem);
  }
}

.session-drawer {
  & .MuiBackdrop-root-MuiModal-backdrop {
    background-color: $light-white;
  }
  & .MuiDrawer-paper {
    margin-top: 6.4rem !important;
    height: 100%;
    width: 55rem;
    padding: 2rem;
  }
}
.session-quill {
  .ql-snow {
    font-family: "Poppins" !important;
  }
  .ql-container {
    font-size: 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .ql-toolbar.ql-snow {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    button {
      height: 1rem;
    }
  }
  .ql-editor {
    min-height: 10rem;

    &.ql-blank::before {
      color: #a199aa;
    }
  }

  .ql-snow.ql-toolbar button {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.session-table-row {
  &.MuiTableBody-root {
    & .MuiTableRow-root {
      vertical-align: baseline !important;

      & .MuiTableCell-root {
        padding: 1rem 0;
      }
    }
  }
}

.date-container {
  display: flex;
  align-items: center;

  .exerciseCheck {
    padding: 0;
    margin: 1.125rem 0.125rem 1.125rem 0;
  }
}

hr {
  margin-top: 1.125rem;
  border-top: 0.063rem solid rgba(0, 0, 0, 0.23);
}
