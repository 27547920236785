@import "./styles/style-guide.scss";

.dashboard-layout {
  font-size: $font_16 !important;
}

.home-layout {
  font-family: $Poppins-Regular !important;
  font-size: $font_16 !important;
}

.home-container {
  display: flex;
  position: relative;
  height: calc(100vh - 5.225rem);
}

.side-navigation {
  background-color: $white;
  border-top: 0.1rem solid $grey95;
  border-right: 0.1rem solid $grey95;
}

.main-page-container {
  padding: 1rem 2rem 0;
  max-width: calc(100% - var(--sidebar-width));
  width: 100%;
  background-color: $light-white;
}

.main-page-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
