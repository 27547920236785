@import "../../styles/style-guide.scss";

.user-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0 1rem;
    border: 0.2rem solid $lightgrey;
    height: calc(100vh - 12rem);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .action-flex {
    display: flex;
    align-items: center;
  }
}
