@import "../../styles/style-guide.scss";

.resource-modal {
  &.MuiDialog-root {
    .MuiDialog-paper {
      border-radius: 1rem;
      width: 41.5rem;
      height: 23.75rem;
      margin: 0;
    }
  }
  & .MuiDialogTitle-root {
    padding: 0;
  }

  & .MuiDialogContent-root {
    padding: 0;
  }
  & .MuiDialogActions-root {
    padding: 0;
  }

  .resource-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 0.063rem solid #9a8f8f70;
  }

  .modal-table-container {
    min-height: 40rem;
    max-height: 70rem;
    scrollbar-gutter: stable;

    .MuiTableHead-root {
      .MuiTableCell-root {
        padding: 2rem;
      }
    }
    .MuiTableCell-root {
      font-size: 2rem;
    }
  }

  .resource-close-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 2rem;
  }
  .resource-table {
    padding: 1rem 2rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-wrap: nowrap;
  }

  .resource-table-pagination {
    padding-left: 0 !important;
    & .MuiTablePagination-toolbar {
      padding-left: 0;
    }
    & .MuiInputBase-root {
      & .MuiTablePagination-select {
        font-size: $font_14;
        min-height: 0;
      }
    }
  }

  .link-btn {
    height: 1.563rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border-color: $pink;
  }
}
