@import "../../styles/style-guide.scss";

.question-type-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0 1rem;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 13rem);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.question-type-table-container {
  & .MuiTableCell-root {
    padding: 0.5rem 1rem !important;
    white-space: nowrap;
  }
}

.question-type-table-row {
  .cell-border {
    border-left: 0.1rem solid #e6dbf0;
    border-top: 0.1rem solid #e6dbf0;
    &:first-child {
      border-right: 0 !important;
      border-top-left-radius: 0.4rem;
    }
    &:last-child {
      border-top-right-radius: 0.4rem;
    }
  }
}
.type-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
