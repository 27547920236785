@import "../../styles/style-guide.scss";

.add-location-modal-title {
  padding: 1.2rem 2rem 0rem !important;
}

.add-location-input {
  margin-top: 1rem !important;
}

.add-location-btn {
  width: 100% !important;
  height: 2.5rem !important;
  border-radius: 0.4rem !important;
}
.add-location-btn:hover {
  background: $pink !important;
  color: #fff;
}
