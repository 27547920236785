@import "../../styles/style-guide.scss";

.assessment-paper-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.MuiPaper-root {
    padding: 1.25rem 0.6rem 1rem 1.25rem;
    height: calc(100vh - 13rem);
    overflow-y: scroll;
  }
}
