@import "../../styles/style-guide.scss";

.body-container {
  margin-top: 2rem;

  .interview-question-parent-container {
    display: flex;
    flex-direction: column;
    &.MuiPaper-root {
      padding: 1rem 1rem 1rem 0.5rem;
      height: calc(100vh - 11rem);
      overflow-y: scroll;
    }

    .accordion-summary {
      &.MuiAccordionSummary-root {
        box-shadow: none;
        border-radius: 0.5rem;
        border: 0.1rem solid $purple-border;
        background: $purple-background;
        min-height: 0;
        height: auto;

        &.Mui-expanded {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none;
          background-color: $white;
        }
      }
    }
  }
}
