@import "../../styles/style-guide.scss";

.interview-paper-container {
  width: 100%;
  height: 100%;

  .interview-main-container {
    height: calc(100vh - 7.2rem);
    overflow-y: scroll;

    .assessment-submitted {
      font-size: 1.3rem;
      text-align: center;
      color: #de1186;
      border: 0.1rem solid #de1186;
      padding: 1rem;
      border-radius: 0.625rem;
      box-shadow: 2px 2px 7px #de1186;
    }

    .contained-items {
      flex-grow: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
    }

    .start-button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      & .MuiButtonBase-root {
        max-width: 18.75rem;
      }
    }

    .interview-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .instructions {
      .upper-container {
        border: 0.1rem solid $grey-border;
      }

      .instruction-details {
        border-style: solid;
        border-left-width: 0.1rem;
        border-color: $grey-border;
      }
      .instruction-details-to-start {
        border-style: solid;
        border-top-width: 0.1rem;
        border-color: $grey-border;
      }

      .instruction-info {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & ul,
        ol {
          list-style-position: inside;
        }
        em {
          font-style: italic;
        }
        h1 {
          font-size: 2em;
        }
        h2 {
          font-size: 1.5em;
        }
        h3 {
          font-size: 1.17em;
        }
        a {
          color: #0066cc;
        }
      }
    }
    .interview-section {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 23.6rem);

      .interview-parent-container {
        padding: 1rem 0.5rem 1rem 1rem;
      }
    }

    .mcq-details {
      flex-grow: 1;

      .question-section {
        flex: 1;

        .question-title {
          margin-bottom: 0.625rem;
        }

        .question-description {
          margin-bottom: 1.25rem;
        }

        .mcq-options {
          margin-top: 0.5rem;
          margin-right: 1rem;
        }

        .options {
          background: #fbf8fe;
          border: 0.1rem solid #e6dbf0;
          border-radius: 0.25rem;
          box-shadow: none;
          height: 2.5rem;
          min-height: 0;
          padding-left: 1rem;
          margin-bottom: 0.5rem;
          width: 100%;

          & .MuiFormControlLabel-label {
            font-size: 0.813rem;
          }
        }

        .selected-option {
          background-color: #05b14e;
          color: #fff;
          border: 0.1rem solid #05b14e;
        }
      }
    }

    .mcq-action {
      padding: 1.875rem 0;
      display: flex;
      align-items: center;
      flex: 0 0 auto;
    }

    .interview-section {
      .options {
        background: #fbf8fe;
        border: 0.1rem solid #e6dbf0;
        border-radius: 0.25rem;
        box-shadow: none;
        height: 2rem;
        min-height: 0;
        padding-left: 1rem;
        margin-bottom: 0.5rem;
        width: 100%;

        & .MuiFormControlLabel-label {
          font-size: 0.813rem;
        }

        &.single-select-options {
          margin-left: 0px;
          margin-right: 0px;
        }
      }

      .selected-option {
        background-color: #05b14e;
        color: #fff;
        border: 0.1rem solid #05b14e;
      }
    }
  }
}
