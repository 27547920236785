@import "./style-guide.scss";

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem !important;
  height: 1.875rem;

  .header-right-icons {
    display: flex;
    align-items: center;
  }
}

.drawer-icon-svg {
  border-radius: 50%;
  font-size: 1.2rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.2s ease;
}

.margin-bottom {
  margin-bottom: 0.5rem !important;
}
.margin-right-1 {
  margin-right: 0.5rem !important;
}
.margin-left-1 {
  margin-left: 0.5rem !important;
}
.margin-right-2 {
  margin-right: 1rem !important;
}
.margin-left-2 {
  margin-left: 1rem !important;
}
.padding-left-1 {
  padding-left: 1rem !important;
}
.padding-right-1 {
  padding-right: 0.5rem !important;
}
.padding-right-2 {
  padding-right: 1rem !important;
}
.padding-bottom {
  padding-bottom: 0.5rem !important;
}
.margin-right-half {
  margin-right: 0.25rem !important;
}
.padding-left-half {
  padding-left: 0.5rem !important;
}
.chip-padding-left-right {
  &.MuiChip-root {
    & .MuiChip-label {
      padding-left: 0.313rem;
      padding-right: 0.313rem;
    }
  }
}

.table-border-remove {
  &.MuiTableCell-root {
    border: 0;
  }
}

.block-button {
  &.MuiButton-root {
    width: 100%;
    border-radius: 0.5rem;
    height: 2.75rem;
  }
}
.block-button:hover {
  background: $pink !important;
  color: #fff;
}

.block-dashed-btn,
.bootcamp-module-add-btn {
  &.MuiButton-root {
    border-width: 0.1rem;
    border-style: dashed;
  }
}

.text-grey-color {
  color: $grey-text !important;
}

.table-pagination-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  &.MuiTableContainer-root {
    box-shadow: none;
  }
  .table-head-row {
    & .MuiTableCell-root {
      background-color: $purple-background;
      border-top: 0.1rem solid $purple-border;
      height: 2.5rem;

      &:first-child {
        border-left: 0.1rem solid $purple-border;
        border-top-left-radius: 0.4rem;
      }
      &:last-child {
        border-right: 0.1rem solid $purple-border;
        border-top-right-radius: 0.4rem;
      }
    }
  }
  .table-body-row {
    height: 2.5rem;
    & .MuiTableCell-root {
      &:first-child {
        border-left: 0.1rem solid $purple-border;
      }
      &:last-child {
        border-right: 0.1rem solid $purple-border;
      }
    }
    &:last-child {
      & .MuiTableCell-root {
        &:first-child {
          border-bottom-left-radius: 0.4rem;
        }
        &:last-child {
          border-bottom-right-radius: 0.4rem;
        }
      }
    }
  }
  & .MuiTableCell-root {
    border-bottom: 0.1rem solid $purple-border;
    padding: 0rem;

    & .MuiTypography-root {
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }
}

.flex-container-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-action-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion {
  margin-bottom: 1rem;
  &.MuiAccordion-root {
    box-shadow: none;
    &.Mui-expanded {
      border-bottom: 0.1rem solid $purple-border;
    }

    &::before {
      height: 0;
    }
    &::after {
      height: 0;
    }
  }

  .accordion-summary {
    &.MuiAccordionSummary-root {
      box-shadow: none;
      border-radius: 0.5rem;
      border: 0.1rem solid $purple-border;
      background: $purple-background;
      min-height: 0;
      height: 2.5rem;

      &.Mui-expanded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        background-color: $white;
      }
    }
  }

  .accordion-details {
    border-left: 1px solid $purple-border;
    border-right: 1px solid $purple-border;
  }
}

.flex-users {
  display: flex;
  align-items: center;
}

.user-cell {
  display: flex;
  align-items: center;
  padding: 0.6rem 0;
}
.boot-module-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-avatar {
  width: 1.97rem !important;
  height: 1.97rem !important;
  margin-right: 0.5rem;

  &.MuiAvatar-root {
    border: 0.2rem solid $purple-border;
  }
}

.user-cell-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.width-100 {
  width: 100% !important;
}

.grey-text {
  color: $grey-text;
}

.header-action-btn {
  &.MuiButton-root {
    border-radius: 0.4rem;
    box-shadow: none;
    height: 1.875rem;

    &:hover {
      box-shadow: none;
    }
  }
}
.auto-complete-checkbox-list {
  margin: 0 0.634rem 0 0 !important;
  padding: 0 !important;
}

.auto-complete-no-options {
  padding: 0.634rem 1rem !important;
}

.multi-tag-input-list-padding {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.4rem;
}
.flex-options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.multi-tag-edit-list-selected {
  display: flex;
  align-items: flex-start;
  padding: 6px 16px;
  cursor: pointer;
  background-color: #e3f2fd;
  &:hover {
    cursor: pointer;
    background-color: #bbdefb;
  }
}
.multi-tag-edit-list-selected-disabled {
  display: flex;
  align-items: flex-start;
  padding: 6px 16px;
  background-color: #e3f2fd;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.multi-tag-style {
  display: flex;
  align-items: flex-start;
  padding: 6px 16px;
  &:hover {
    cursor: pointer;
    background-color: #0000000a;
  }
}

.action-head-btn {
  &.MuiButton-root {
    height: 1.875rem;
  }
}
//////////  Quill  Editor Styles ///////////////////
.ql-container {
  font-size: 1rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.ql-editor {
  min-height: 12rem;
  padding: 1rem;

  em {
    font-style: italic;
  }
}

.ql-snow.ql-toolbar button {
  width: 1.5rem;
  height: 1.5rem;
}

.ql-editor.ql-blank::before {
  content: attr(data-placeholder);
  font-style: unset;
}

////////////////////////////////////////////////////////

.flex-table-sort-head {
  width: 100%;
  display: flex;
  align-items: center;

  .table-sort-head {
    margin-right: 0.5rem;
  }
  .table-sort-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.grey-text {
  color: $grey-text !important;
}

.filterButton {
  border-radius: 50%;
  padding: 0.5rem 0.782rem;
}

.css-wgai2y-MuiFormLabel-asterisk {
  color: $red;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.cursor-auto {
  pointer-events: auto;
  cursor: auto;
}

.same-size-chip {
  &.MuiChip-root {
    width: 4.375rem;
  }
}

.same-size-type-chip {
  &.MuiChip-root {
    width: 5.625rem;
  }
}
.more-info-chip {
  &.MuiChip-root {
    width: 7.625rem;
  }
}

.adjusted-height {
  height: auto !important;
}

.text-cursor {
  cursor: text;
}
