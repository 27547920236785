@import "../../styles/style-guide.scss";

.review-modal {
  .review-dialog-title {
    &.MuiDialogTitle-root {
      padding: 1.75rem 1.875rem 1rem;
      font-size: $font_16;
      font-weight: 500;
      border-bottom: 0.063rem solid #9a8f8f70;
    }
  }

  .review-modal-content {
    z-index: 1300;
    border-radius: 0.625rem;
    margin: 0;
    padding-top: 1rem !important;
    padding-bottom: 1.25rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .review-dialog-action {
    &.MuiDialogActions-root {
      padding: 0rem 2rem 1rem;
    }
  }

  .review-dialog-score {
    margin-bottom: 0.75rem;
  }
}
