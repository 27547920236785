@import "../../styles/style-guide.scss";

.email-checkboxes-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.688rem;

  .email-checkboxes {
    display: flex;
    align-items: center;
  }
}

.send-dialog-title {
  padding: 2rem 2rem 1.2rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  border-bottom: 0.063rem solid #9a8f8f70;
}

.send-dialog-action {
  padding: 0 2rem 1.25rem !important;
}
