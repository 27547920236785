@import "../../styles/style-guide.scss";

.location-head-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .location-head {
    display: flex;
    align-items: center;
  }

  .room-dashed-btn {
    height: 2.5rem !important;
  }
}
