@import "../../styles/style-guide.scss";

.add-question-modal-title {
  padding: 2rem 2rem 1rem !important;
  font-size: $font_16 !important;
  font-weight: 500 !important;
  border-bottom: 0.063rem solid #9a8f8f70;
}

.add-question-btn {
  width: 100% !important;
  height: 2.75rem !important;
  border-radius: 1rem !important;
}
.add-question-btn:hover {
  background: $pink !important;
  color: #fff;
}

.create-bulk-exercise-questions-modal {
  &.MuiDialog-root {
    .MuiDialog-paper {
      width: 24.125rem;
      max-height: 24.5rem;
      border-radius: 0.6rem;
      margin: 0;
    }
    & .MuiDialogContent-root {
      padding: 0;
    }
  }
  .dialog-title {
    display: flex;
    align-items: center;
    padding: 2rem 2rem 0.8rem;
    border-bottom: 0.063rem solid #9a8f8f70;
  }

  & .MuiDialogActions-root {
    padding: 0;
  }

  .resource-modal-dialog-content {
    padding: 1rem 2rem 0 !important;

    .download-template-btn {
      text-transform: capitalize;
      margin-top: 1rem;
    }

    .fileError {
      color: #d32f2f;
      margin-top: 0.313rem;
      margin-bottom: 0.625rem;
      margin-left: 0.875rem;
    }

    .batch-creation-error {
      color: #d32f2f;
      font-size: 0.75rem;
      font-family: Poppins, sans-serif;
      margin-top: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  .create-bulk-exercise-questions-modal-dialog-action {
    padding: 0 2rem 1rem !important;
    .create-btn {
      width: 100%;
      height: 2.75rem !important;
      border-radius: 0.4rem !important;
      margin-top: 1.25rem;
    }
    .create-btn:hover {
      background: $pink !important;
      color: #fff;
    }
  }

  .upload-btn {
    &.MuiButton-root {
      border-radius: 0.4rem;
      margin-top: 0.6rem;
      background-color: rgb(248, 248, 248);
      text-transform: none;
      border: none;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .file-upload-instruction {
      line-height: 1;
    }
  }
}