@import "../../styles/style-guide.scss";

.home-paper-container {
  width: 100%;
  overflow-y: scroll;

  &.MuiPaper-root {
    padding: 1rem;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 10rem);
  }
  .no-data-container {
    height: calc(100vh - 13.875rem);
  }
}

.home-main-container {
  width: 100%;
  background-color: $white;

  .active{
    background-color: #F6F8FF;
    border: 0.1rem solid #B4CDFE;
  }

  .scheduled{
    background-color: #FFFAF5;
    border: 0.1rem solid #FDE4C4;
  }

  .completed{
    background-color: #F2FBFA;
    border: 0.1rem solid #BDE7E1;
  }

  .sub-module-active{
    background-color: #E2EBFFD9;
    border: 0.063rem solid #B4CDFE;
  }

  .sub-module-scheduled{
    background-color: #FEF6E1D9;
    border: 0.063rem solid #FDE4C4;
  }

  .sub-module-completed{
    background-color: #D9F2EFd9;
    border: 0.063rem solid #BDE7E1;
  }

  .home-main-cell-container {
    box-shadow: none;
    border-radius: 0.6rem !important;
    padding: 0;
    margin-bottom: 1rem;
    &.MuiAccordion-root:before {
      height: 0;
    }

    .home-cell-container {
      &.MuiAccordionSummary-root {
        padding: 0 1rem 0 0;
        & .Mui-expanded {
          margin: 0 0 1rem 0;
        }
      }

      & .MuiAccordionSummary-content {
        display: flex;
        flex-direction: row;
        margin: 0;
        & .Mui-expanded {
          margin: 0;
        }
      }
      .module-left-container {
        min-height: 100%;
        width: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;

        .module-left-head {
          line-height: 1;
          transform: rotate(-90deg);
          white-space: nowrap;
        }
      }

      .module-right-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 0 1rem 1rem;

        .cell-left-container {
          display: flex;
        }

        .home-cell-head-container {
          display: flex;
          align-items: baseline;
          flex-wrap: nowrap;
          justify-content: space-between;

          .home-cell-main-head {
            display: flex;
            align-items: center;

            .home-cell-heading {
              margin-right: 1rem;
              word-wrap: break-word;
            }
          }
        }

        .home-info-container {
          margin-top: 0.8rem;
          .home-dates-container {
            display: flex;
            flex-direction: column;
            .dates-head-container {
              display: flex;
              align-items: center;
              .dates-head-title {
                color: $grey-text;
              }
              & .MuiIconButton-root {
                padding: 0 0.5rem 0 0;
              }
            }
            .dates-body-container {
              display: flex;
              align-items: center;
              padding-left: 1.313rem;
            }
          }
        }

        .home-middle-cell-section {
          display: flex;
          flex-direction: column;
        }
      }
    }
    & .MuiAccordionActions-root {
      padding: 1rem 1rem 1rem 2rem;
    }
  }

  .expanded-module-collapse {
    &.MuiAccordionDetails-root {
      padding: 0 1rem 0 2rem;
    }

    .expanded-module-container {
      width: 100%;
      min-height: 6.688rem;
      background-color: $white;
      border-radius: 0.6rem;
      overflow: hidden;
      margin-bottom: 0.5rem;
      display: flex;

      .sub-module-left-head-container {
        min-height: 100%;
        width: 1.875rem;

        display: flex;
        align-items: center;
        justify-content: center;

        .sub-module-left-head {
          line-height: 1;
          transform: rotate(-90deg);
        }

        .sub-module-left-head-nowrap {
          white-space: nowrap;
        }
      }

      .sub-module-middle-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .sub-module-head-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.6rem;

          .sub-module-heading {
            display: flex;

            .sub-module-head {
              margin-right: 1.5rem;
              display: flex;
              align-items: center;
            }
          }
          .sub-module-buttons {
            display: flex;
            align-items: center;
          }
        }

        .sub-modal-cells-container {
          padding: 0 0.6rem;
          margin-bottom: 0.6rem;

          .sub-modal-cells {
            display: flex;
            flex-direction: column;
            padding: 0.4rem 0.6rem;
            border: 0.1rem solid #cfc9d5;

            .sub-modal-cell-subtitle {
              color: $grey-text;
              margin-bottom: 0.4rem;
            }
          }
        }
      }
    }
  }
}

.bootcamp-module-add-btn {
  &.MuiButton-root {
    height: 2.5rem;
    width: calc(50% - 0.5rem);
  }
}
.dialog-content {
  .css-1pn0rsm-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: $grey900;
  }
}
.bootcamp-modal-name {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}