$pink: #de1186;
$white: #fff;
$grey95: #f2f2f2;
$grey900: #212529;
$milkywhite: #fafafa;
$lightgrey: #eff0f5;
$light-white: #f8f8f8;
$grey: #f5f6f8;
$black: #000;
$lightgreen: #caedd3;
$grey500: #adb5bd;
$red: #ff2e63;
$grey-border: #d3cfd6;
$grey-text: #948b9e;
$purple-border: #e6dbf0;
$purple-background: #fbf8fe;
$lightYellow: #fff9e8;
$lightOrange: #ffdbb3;
$backdrop-purple: #2e1c41a3;
$light-white-border: #ecedf2;
/* Size and spacing */
$gutter: 2.4rem;
$header-height: 7.4rem;
$footer-height: 7.4rem;
/* font family */

$base-font-family: "Poppins-Regular", sans-serif;
$Poppins-Regular: "Poppins-Regular", sans-serif;
$Poppins-Medium: "Poppins-Medium", sans-serif;

$font_20: 1.25rem;
$font_16: 1rem;
$font_15: 0.938rem;
$font_14: 0.875rem;
$font_13: 0.813rem;
$font_12: 0.75rem;
$font_11: 0.688rem;
$font_10: 0.625rem;
$font_9: 0.563rem;

/* Font Weigth */
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight-boldest: 800;
