@import "../../styles/style-guide.scss";

.filter-drawer-container {
  &.MuiDrawer-root {
    & .MuiBackdrop-root {
      background-color: $backdrop-purple;
    }
  }
  & .MuiDrawer-paper {
    padding-top: 5.125rem !important;
    height: 100%;
    width: 29rem;
    padding: 1rem 0rem;
  }

  .filter-main-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: space-between;
  }
  .drawer-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0rem;
  }

  .dropdown-menu {
    &.MuiMenuItem-root {
      max-height: 10rem;
    }

    &.MuiMenuItem-root.Mui-selected {
      background-color: $pink !important;
      color: $white;
    }
  }
  .filter-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.063rem solid #9a8f8f70;
    padding: 1rem 2rem;
  }
  .filter-head-close {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0rem 2rem;
  }
  .filter-title {
    font-weight: 500 !important;
  }
  .filter-padding {
    padding: 0rem 2rem;
  }
  .textfield-box {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .filter-btn {
    width: 12.25rem !important;
    height: 2.75rem !important;
    border-radius: 0.4rem;
  }
  .filter-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
}
