@import "../../styles/style-guide.scss";

.performance-accordion-summary {
  & .MuiTableCell-root {
    border: 0 !important;
  }
  &.MuiAccordionSummary-root {
    box-shadow: none;
    // background: $purple-background;
    min-height: 0;
    padding: 0;

    &.Mui-expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
      min-height: auto;
    }

    & .MuiAccordionSummary-content {
      margin: 0;
      flex-direction: column;
      background: $purple-background;
      border-bottom: 0.1rem solid #e6dbf0;
    }
    .related-chip {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
    }
    .related-chip-info {
      display: flex;
      align-items: center;

      .level-chip {
        margin-right: 1rem;
      }
      .type-chip {
        margin-right: 1rem;
      }
      .more-info:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.performance-accordion {
  &.MuiAccordion-root {
    box-shadow: none;
    &.Mui-expanded {
      border-bottom: 0;
    }

    &::before {
      height: 0;
    }
    &::after {
      height: 0;
    }
  }

  .accordion-summary {
    &.MuiAccordionSummary-root {
      box-shadow: none;
      border-radius: 0.5rem;
      border: 0.1rem solid $purple-border;
      background: $purple-background;
      min-height: 0;
      height: 2.5rem;

      &.Mui-expanded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        background-color: $white;
      }
    }
  }

  .accordion-details {
    padding: 0;
  }
}

.performance-parent-container {
  .performance-table-container {
    &.MuiTableContainer-root {
      box-shadow: none;
    }
    .performance-table-head-row {
      & .MuiTableCell-root {
        background-color: $purple-background;
        border-top: 0.1rem solid $purple-border;
        height: 2.5rem;

        &:first-child {
          border-left: 0.1rem solid $purple-border;
          border-top-left-radius: 0.4rem;
        }
        &:last-child {
          border-right: 0.1rem solid $purple-border;
          border-top-right-radius: 0.4rem;
        }
      }
    }
    .performance-table-body-row {
      height: 2.5rem;
      .performance-table-body-column {
        border: 0;
      }
      & .MuiTableCell-root {
        &:first-child {
          border-left: 0.1rem solid #e6dbf0;
        }
        &:last-child {
          border-right: 0.1rem solid #e6dbf0;
        }
      }
      .performance-row {
        height: 2.5rem;
        & .MuiTableCell-root {
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
    & .MuiTableCell-root {
      border-bottom: 0.1rem solid #e6dbf0;
      padding: 0rem;

      & .MuiTypography-root {
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }
  }
}
