@import "../../styles/style-guide.scss";

.body-container {
  margin-top: 2rem;

  .location-parent-container {
    display: flex;
    flex-direction: column;
    &.MuiPaper-root {
      padding: 1rem;
      border: 0.2rem solid $lightgrey;
      height: calc(100vh - 11rem);
      overflow-y: scroll;
    }

    .no-data-container {
      height: calc(100vh - 11rem);
    }
  }
}
