@import "../../styles/style-guide.scss";

.user-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0 1rem;
    border: 0.2rem solid $lightgrey;
    height: calc(100vh - 10rem);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.evaluation-search-input {
  & .MuiInputBase-root {
    &.MuiOutlinedInput-root {
      height: 1.875rem !important;
      background: white;
    }
  }
}

.view-evaluation-header {
  display: flex;
  align-items: center;
  height: 2rem;
}

.assessment-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 13rem);
    overflow: scroll;
  }
  .margin-bottom-5 {
    margin-bottom: 1.375rem;
  }
}

.topic-list-container {
  word-break: break-word;

  .topic-details {
    padding: 0.938rem;
    background-color: $purple-background;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0.625rem;
    justify-content: space-between;
    border-radius: 0.5rem;

    .eval-topic-btn {
      text-transform: none;
      text-align: start;
      color: #2e1c41;
      font-size: 0.875rem;
      padding: 0;
      margin-right: 1rem;
    }

    .score-chip {
      &.MuiChip-root {
        width: 3.125rem;
      }
    }
  }
}
