@import "../../styles/style-guide.scss";

.module-no-data-container {
  border: 0.2rem solid $lightgrey;
  height: calc(100vh - 11rem);
}

.view-assessment-header {
  display: flex;
  align-items: center;
  height: 2rem;
}

.view-assessment-paper-container {
  &.MuiPaper-root {
    padding: 1rem 1rem 0;
    border: 0.1rem solid $lightgrey;
    height: calc(100vh - 12rem);
    overflow: scroll;
  }
  .margin-bottom-5 {
    margin-bottom: 1.375rem;
  }
}

.view-assessment-container {
  border: 0.1rem solid $grey-border;
}
.view-assessment-ins-container {
  border: 0.1rem solid $grey-border;
  margin-top: 1.25rem;

  .insturctions-details {
    & ul,
    ol {
      list-style-position: inside;
    }
    em {
      font-style: italic;
    }
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.17em;
    }
    a {
      color: #0066cc;
    }
  }
}

.view-assessment-right-info {
  border-style: solid;
  border-left-width: 0.1rem;
  border-color: $grey-border;

  .view-assessment-info-box1,
  .view-assessment-info-box2,
  .view-assessment-info-box3,
  .view-assessment-info-box4,
  .view-assessment-info-box5 {
    border-style: solid;
    border-color: $grey-border;
  }

  .view-assessment-info-box1 {
    border-right-width: 0.1rem;
    border-bottom-width: 0.1rem;
  }
  .view-assessment-info-box2 {
    border-bottom-width: 0.1rem;
  }
  .view-assessment-info-box3 {
    border-right-width: 0.1rem;
  }
  .view-assessment-info-box5 {
    border-left-width: 0.1rem;
  }
}
.view-assessment-info-box {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

////////////////////////////////////////////////////////////////

.module-chip-status {
  & .MuiChip-label {
    font-weight: 500;
    padding: 0 0.313rem;
  }
}
.option-details {
  padding: 0.625rem;
  background-color: $purple-background;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.625rem;
  border-radius: 0.313rem;
}
